<template>
  <div>
    <NavigationBar
      titel="Neue Vorlage erstellen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form
              v-model="helpers.formIsValid"
              ref="form"
              @submit.prevent="createItem"
            >
              <v-col cols="12">
                <Subheader icon="mdi-book" title="Vorlage"></Subheader>
                <div class="mt-6">
                  <v-card>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="9">
                            <v-text-field
                              v-model.trim="data.template.title"
                              :counter="35"
                              label="Bezeichnung"
                              required
                              :rules="[rules.required]"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model.number="data.template.sortKey"
                              label="Sortierschlüssel"
                              prepend-inner-icon="mdi-sort-numeric-ascending"
                              type="number"
                              hint="Vorlagen werden aufsteigend nach ihrem Sortierschlüssel sortiert."
                              persistent-hint
                              required
                              :rules="[rules.required]"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              v-model.trim="data.template.description"
                              counter
                              clearable
                              rows="4"
                              no-resize
                              auto-grow
                              label="Beschreibung"
                              outlined
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12">
                <event-manage-details
                  isInTemplateMode
                  v-model="data.data"
                ></event-manage-details>
              </v-col>
              <v-col cols="12">
                <event-manage-settings
                  isInTemplateMode
                  v-model="data.data.config"
                ></event-manage-settings>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import { SCHEDULING } from "@/store/modules.js";
import { CREATE_EVENT_TEMPLATE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import EventManageDetails from "@/components/scheduling/EventManageDetails.vue";
import EventManageSettings from "@/components/scheduling/EventManageSettings.vue";

export default {
  name: "scheduling-config-tab-templates-template-new",
  components: {
    NavigationBar,
    Subheader,
    EventManageDetails,
    EventManageSettings,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },

      helpers: {
        formIsValid: false,
      },

      data: {
        template: {
          title: null,
          description: null,
          sortKey: null,
        },
        data: {
          title: null,
          description: null,
          type: {
            id: null,
            title: null,
            shortTitle: null,
            color: null,
            description: null,
            dutyType: {
              title: null,
              id: null,
            },
          },
          location: {
            name: null,
          },
          requiredPPE: [],
          tasks: [],
          units: [],
          duration: {
            start: {
              time: null,
              timestamp: null,
              isOpen: false,
            },
            end: {
              time: null,
              timestamp: null,
              isOpen: false,
            },
          },

          details: [],
          config: {
            organizer: {
              isAppointed: false,
              hasAdditionalPermissions: false,
              description: null,
              personIds: [],
              personData: [],
            },
            attendance: {
              isMandatory: false,
              isLimited: false,
              description: null,
              minAttendees: null,
              maxAttendees: null,
              allowedAttendeesIds: ["all"],
              allowedPersons: [],
              allowedUserGroups: [
                {
                  title: "Alle",
                  id: "all",
                },
              ],
            },
            responses: {
              areBinding: false,
              areLocked: false,
              deadline: {
                isCustom: false,
                inMinutes: null,
              },
            },
          },
        },
      },
    };
  },
  computed: {
    newSortKey() {
      return this.$store.state.scheduling.eventTemplates.length + 1;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.data.template.sortKey = this.newSortKey;
    },
    validate() {
      this.$refs.form.validate();
    },

    checkAttendeesData() {
      const hasItems = (array) => array.length > 0;
      const filterOutAllString = (array) =>
        array.filter((item) => item !== "all");
      const filterOutAllObject = (array) =>
        array.filter((item) => item.id != "all");

      const attendance = this.data.data.config.attendance;
      const allowedPersonsIds = attendance.allowedPersons.map(
        (item) => item.uid
      );
      const allowedUserGroupsIds = attendance.allowedUserGroups
        .map((item) => item.id)
        .filter((item) => item != "all");

      const attendanceIsLimited = attendance.isLimited;
      const noAttendanceDescription = attendance.description ? false : true;
      const noAttendanceLimitationsSelected =
        allowedPersonsIds.length == 0 && allowedUserGroupsIds.length == 0;

      // if no persons or user groups are selected, make sure all is included as default
      if (noAttendanceLimitationsSelected) {
        attendance.allowedAttendeesIds = filterOutAllString(
          attendance.allowedAttendeesIds
        );
        attendance.allowedUserGroups = [{ title: "Alle", id: "all" }];
      }
      // 1: else if user groups but no persons are selected, make sure all is not included
      // 2: else if persons but no user groups are selected, make sure all is not included
      // 3: else if persons and user groups are selected, make sure all is not included
      else if (
        (allowedPersonsIds.length == 0 && hasItems(allowedUserGroupsIds)) ||
        (hasItems(allowedPersonsIds) && allowedUserGroupsIds.length == 0) ||
        (hasItems(allowedPersonsIds) && hasItems(allowedUserGroupsIds))
      ) {
        attendance.allowedAttendeesIds = filterOutAllString(
          attendance.allowedAttendeesIds
        );
        attendance.allowedUserGroups = filterOutAllObject(
          attendance.allowedUserGroups
        );
      } else {
        console.log("error in attendance data");
      }

      if (!attendanceIsLimited) {
        attendance.allowedUserGroups = [{ title: "Alle", id: "all" }];
        attendance.allowedPersons = [];
      }

      if (
        attendanceIsLimited &&
        noAttendanceDescription &&
        noAttendanceLimitationsSelected
      ) {
        attendance.isLimited = false;
      }

      const personAttendanceIds = attendance.allowedPersons.map(
        (item) => item.uid
      );
      const userGroupAttendanceIds = attendance.allowedUserGroups.map(
        (item) => item.id
      );

      attendance.allowedAttendeesIds = personAttendanceIds.concat(
        userGroupAttendanceIds
      );
    },
    createItem() {
      this.checkAttendeesData();

      this.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${SCHEDULING}${CREATE_EVENT_TEMPLATE}`, {
          organizationId: this.$route.params.organizationId,

          template: {
            title: this.data.template.title,
            description: this.data.template.description,
            sortKey: this.data.template.sortKey,
          },

          data: {
            title: this.data.data.title,
            description: this.data.data.description,
            type: {
              id: this.data.data.type.id,
              title: this.data.data.type.title,
              shortTitle: this.data.data.type.shortTitle,
              color: this.data.data.type.color,
              description: this.data.data.type.description,
              dutyType: {
                title: this.data.data.type.dutyType.title,
                id: this.data.data.type.dutyType.id,
              },
            },
            location: {
              name: this.data.data.location.name,
            },
            requiredPPE: this.data.data.requiredPPE,
            tasks: this.data.data.tasks,
            units: this.data.data.units,
            duration: {
              start: {
                time: this.data.data.duration.start.time,
                isOpen: this.data.data.duration.start.isOpen,
              },
              end: {
                time: this.data.data.duration.end.time,
                isOpen: this.data.data.duration.end.isOpen,
              },
            },

            details: [],

            config: {
              organizer: {
                isAppointed: this.data.data.config.organizer.isAppointed,
                hasAdditionalPermissions:
                  this.data.data.config.organizer.hasAdditionalPermissions,
                description: this.data.data.config.organizer.description,
                personIds: this.data.data.config.organizer.personIds,
                personData: this.data.data.config.organizer.personData,
              },
              attendance: {
                isMandatory: this.data.data.config.attendance.isMandatory,
                isLimited: this.data.data.config.attendance.isLimited,
                description: this.data.data.config.attendance.description,
                minAttendees: this.data.data.config.attendance.minAttendees,
                maxAttendees: this.data.data.config.attendance.maxAttendees,
                allowedAttendeesIds:
                  this.data.data.config.attendance.allowedAttendeesIds,
                allowedPersons: this.data.data.config.attendance.allowedPersons,
                allowedUserGroups:
                  this.data.data.config.attendance.allowedUserGroups,
              },
              responses: {
                areBinding: this.data.data.config.responses.areBinding,
                areLocked: this.data.data.config.responses.areLocked,
                deadline: {
                  isCustom: this.data.data.config.responses.deadline.isCustom,
                  inMinutes: this.data.data.config.responses.deadline.inMinutes,
                },
              },
            },
          },
        });
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>

<style></style>
